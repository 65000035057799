<template>
  <main id="home">
    <PageHeader :title="$t('profile.title')" :items="items" />

    <b-row style="width: 100%; margin: 10px 0">
      <b-col md="auto" class="col-userinfo">
        <h3>{{ $t('profile.info_user') }}</h3>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="i1" class="label">{{ $t('profile.name') }}</label>
            <input type="text" class="form-control input" id="i1" :placeholder="user.nome" aria-label="Small"
              aria-describedby="inputGroup-sizing-sm" readonly />
          </div>

          <div class="col">
            <label for="i2" class="label">{{
              $t('profile.label_login')
            }}</label>
            <input type="text" class="form-control input" id="i2" :placeholder="user.login" aria-label="Small"
              aria-describedby="inputGroup-sizing-sm" readonly />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="i3" class="label">Senha Email</label>
            <input type="password" class="form-control input" id="i3" v-model="senhaEmail" aria-label="Small"
              aria-describedby="inputGroup-sizing-sm" />
          </div>

          <div class="form-group col-md-6 d-flex align-items-end">
            <b-button @click="salvaSenhaEmail" class="form-control">Salvar Senha Email</b-button>
          </div>

        </div>
      </b-col>

    </b-row>
    <b-spinner v-if="loading" text-align="center" variant="success" label="Spinning" class="ml-2 mt-3" />
  </main>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import PageHeader from '@/components/page-header';
import { i18n } from '../../main';
import { salvaSenhaEmail } from './profile.services';
import { createNamespacedHelpers } from 'vuex';
import { getAuth } from '../auth/auth.helpers';

Vue.use(VueMask);

const { mapState } = createNamespacedHelpers('auth');
const { user } = getAuth();

export default {
  name: 'Home',
  components: { PageHeader },
  data() {
    return {
      title: i18n.tc('profile.title'),
      userLogin: user.login,
      loading: false,
      senhaEmail: '',
    };
  },
  methods: {
    salvaSenhaEmail() {
      this.loading = true;

      salvaSenhaEmail(this.senhaEmail)
        .then((response) => {
          if (response.status) {
          } else {
            console.error(
              'Resposta do servidor não está no formato esperado:',
              response
            );
          }
        })
        .catch((error) => {
          console.error('Erro:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    items: () => [
      {
        text: 'Dashboards',
        href: '/'
      },
      {
        text: i18n.tc('profile.title'),
        active: true
      }
    ],
    ...mapState(['user'])
  }
};
</script>

<style scoped>
.label {
  margin-left: 10px;
  margin-top: 10px;
}

.input {
  cursor: default;
}

.col-userinfo {
  background-color: #fff;
  padding: 25px;
  margin: 5px;
  border-radius: 10px;
  width: 48%;
}

@media (max-width: 768px) {

  .col-userinfo {
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;
    width: 100%;
  }
}
</style>
